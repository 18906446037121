import { useState, useEffect } from 'react';
import { GeneraFormularioSimple } from "../../components/globals/globalForms";
import { editArticle } from "../../data/forms/articles";
import { useParams } from "react-router-dom";

import * as yup from "yup";

import { UseAppConfig } from '../../system/config';
import { getPostData } from '../../system/getData';

const EditRcd = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
		const dataConfig = {
			dbID: "id",
			title: "Editar Articulo",
			key: editArticle,
			section: 'articles',
			module: 'articles',
			description: "Ingrese los datos necesarios para editar los datos de este Articulo",
			titleBtn: "Editar Articulo",
			colorBtn: "secondary",
			msgSuccess: "Articulo editado exitosamente",
			msgError: "Error al editar el Articulo",
			getData: "detalle/articulo",
			sendData: "edita/articulo"
		}
		
		const validateSchemas = yup.object().shape({
			clave: yup.string().required('Requerido'),
			famID: yup.number().required('Requerido'),
			cuentaID: yup.number().required('Requerido'),
			unidadID: yup.number().required('Requerido'),
			descCorta: yup.string().required('Requerido'),
			descLarga: yup.string().required('Requerido'),
			imagen: yup.string().required('Requerido'),
			status: yup.string().required('Requerido')
		});
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		const { id } = useParams();
		const [ initValues, setInitValues ] = useState(null);
		const [ loading, setLoading ] = useState(true);

		const { config, configData } = UseAppConfig();

		useEffect(() => {
			const fetchData = async () => {
				const initVals = await getPostData(`${config.API_BASE_URL}/${dataConfig.getData}`, { id });
				setInitValues(initVals);
				setLoading(false);
			};
			fetchData();
		}, [id, dataConfig.getData]);

		if (loading) {
			return <div>Cargando...</div>;
		}
		console.log('Valores iniciales: ' + JSON.stringify(initValues));

		return (
			<GeneraFormularioSimple
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
			/>
		);
	// ========================================================================================
}

export default EditRcd;
