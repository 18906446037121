import { DataTable } from "../../components/globals/dataTable";
import { compoundsHeaders } from "../../data/tableHeaders/compounds";

const Inventory = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
		const dataConfig = {
			title: "Compuestos",
			module: "compounds",
			tableHeader: compoundsHeaders,
			description: "Listado de Compuestos registrado en el sistema",
			endpoint: "listado/compuestos"
		}
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<DataTable data={dataConfig} />
		)
	// ========================================================================================
}

export default Inventory;