import CatalogList from "../../components/catalogs/catalogList";
import { tableHeaders } from "../../data/tableHeaders/catalogs";

const Catalogs = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
	const catalogsConfig = {
		title: "Catálogos",
		module: "catalogs",
		tableHeader: tableHeaders,
		description: "Listado de Catálogos registrado en el sistema",
		endpoint: ""
	}
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<CatalogList data={catalogsConfig} />
		)
	// ========================================================================================
}

export default Catalogs;