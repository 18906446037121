
const calculatePrecioVenta = (precioCompra, precioVenta, porcentajeGlobal = 0) => {
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    let precioVentaValor;
    let precioVentaFormatted;

    if (precioVenta === 0) {
        precioVentaValor = precioCompra * (porcentajeGlobal / 100) + precioCompra;
        precioVentaFormatted = precioVentaValor;
    } else if (precioVenta > 1) {
        precioVentaValor = precioVenta;
        precioVentaFormatted = precioVentaValor;
    } else if (precioVenta < 1) {
        precioVentaValor = precioCompra * (1 - precioVenta);
        precioVentaFormatted = precioVentaValor;
    } else {
        precioVentaValor = 0;
        precioVentaFormatted = '';
    }

    return {
        precioVentaValor,
        precioVentaFormatted,
        isPrecioVentaMenor: precioVentaValor < precioCompra
    };
};

export default calculatePrecioVenta;