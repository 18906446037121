import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { tokens } from "../data/tokens";

export const themeSettings = (mode) => {
	const colors = tokens(mode);
	return {
		palette: {
			mode: mode,
			...(mode === "dark"
				? {
					// Colores para el Tema Obscuro
					primary: {
						main: colors.primary[500],
					},
					secondary: {
						main: colors.greenAccent[500],
					},
					neutral: {
						dark: colors.grey[700],
						main: colors.grey[500],
						light: colors.grey[100],
					},
					background: {
						default: colors.primary[500],
					},
				}
				: {
					// Colores para el Tema Claro
					primary: {
						main: colors.primary[500],
					},
					secondary: {
						main: colors.greenAccent[500],
					},
					neutral: {
						dark: colors.grey[700],
						main: colors.grey[500],
						light: colors.grey[100],
					},
					background: {
						default: "#fcfcfc",
					},
				}),
		},
		typography: {
			fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
			fontSize: 12,
			h1: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 40,
			},
			h2: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 32,
			},
			h3: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 24,
			},
			h4: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 20,
			},
			h5: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 16,
			},
			h6: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 14,
			},
		},
	};
};

// context for color mode
export const ColorModeContext = createContext({
	toggleColorMode: () => { },
});

export const useMode = () => {
	const [mode, setMode] = useState("light");
	const colorMode = useMemo(
		() => ({
			toggleColorMode: () =>
				setMode((prev) => (prev === "light" ? "dark" : "light")),
		}),
		[]
	);

	const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
	return [theme, colorMode];
};