
export const tableHeaders = [
	{
		"field": "costos",
		"name": "Catálogos de Costos"
	},
	{
		"field": "cuenta",
		"name": "Catálogos de Cuentas"
	},
	{
		"field": "familia",
		"name": "Catálogos de Familias"
	},
	{
		"field": "ubicacion",
		"name": "Catálogos de Ubicaciones"
	},
	{
		"field": "unidad",
		"name": "Catálogos de Unidades"
	},
	{
		"field": "tipo",
		"name": "Catálogos de Artículos"
	},
	{
		"field": "facturas",
		"name": "Catálogos de Facturas"
	},
	{
		"field": "movimientos",
		"name": "Catálogos de Movimientos"
	},
	{
		"field": "polizas",
		"name": "Catálogos de Pólizas"
	},
	{
		"field": "usuarios",
		"name": "Catálogos de Usuarios"
	}
];