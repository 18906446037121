import { DataTable } from "../../components/globals/dataTable";
import { tableHeaders } from "../../data/tableHeaders/history";

const Users = () => {
	const dataConfig = {
		title: "Historial",
		module: "history",
		tableHeader: tableHeaders,
		//editLink1: "/users/edit",
		description: "Historial de cambios realizados en el Sistema",
		endpoint: "listado/historial"
	}
	return (
		<DataTable data={dataConfig} />
	)
}

export default Users;