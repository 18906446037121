
export const tableHeaders = [
	{
		"field": "costos",
		"name": "Catálogos de Costos",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 1,
				"minWidth": 200
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "cuenta",
		"name": "Catálogos de Cuentas",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "familia",
		"name": "Catálogos de Familias",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"width": 200
			},
			{
				"field": "descripcion",
				"headerName": "Descripción",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "ubicacion",
		"name": "Catálogos de Ubicaciones",
		"columns": [
			{
				"field": "ubicacion",
				"headerName": "Ubicación",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "fecha",
				"headerName": "Fecha",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "unidad",
		"name": "Catálogos de Unidades",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "tipo",
		"name": "Catálogos de Artículos",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "facturas",
		"name": "Catálogos de Facturas",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "movimientos",
		"name": "Catálogos de Movimientos",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "polizas",
		"name": "Catálogos de Pólizas",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"width": 200
			},
			{
				"field": "simbolo",
				"headerName": "Símbolo",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	},
	{
		"field": "usuarios",
		"name": "Catálogos de Usuarios",
		"columns": [
			{
				"field": "nombre",
				"headerName": "Nombre",
				"headerAlign": "center",
				"align": "center",
				"width": 200
			},
			{
				"field": "descripcion",
				"headerName": "Descripción",
				"headerAlign": "center",
				"align": "center",
				"flex": 1
			},
			{
				"field": "status",
				"headerName": "Status",
				"headerAlign": "center",
				"align": "center",
				"width": 100
			}
		]
	}
];