
import React, { useEffect, useState } from 'react';
import { Box, useTheme, Grid, Typography, Paper, Modal, Button } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';
import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';

import { getPostData } from '../../system/getData';
import { CreateSection, CreateProject } from "./addElements";

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: 'lightslategrey',
		color: '#fff',
		fontSize: '14px',
		height: '10px',
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'bold',
	},
	'& .MuiDataGrid-cell': {
		padding: '8px',
		fontSize: '14px',
	},
}));

const formatCurrency = (value) => {
	return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

const ProjectsList = ({ data }) => {
	const navigate = useNavigate();

	const [rows, setRows] = useState({});
	const [error, setError] = useState(null);
	const [isLoading, setLoading] = useState(true);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalSection, setModalSection] = useState(false);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();

	const fetchDataTable = async () => {
		try {
			setLoading(true);
			const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`);
			const formatData = jsonData.map(row => ({
				...row,
				status: row.status == 1 ? 'Activo' : 'Desactivado'
			}));
			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
	}, [data.endpoint]);

	if (isLoading) {
		return <div>Cargando...</div>;
	}

	if (error) {
		return <div>{error}</div>;
	}

	const handleModalOpen = ( section ) => {
		setModalSection(section);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const handleProjectClick = (id) => {
		navigate(`details/${id}`);
	};

	return (
		<Grid p={(4)} container justifyContent="center" className="row-printable">
			<Grid item xs={12} md={12}>
				<Box display="flex" justifyContent="end" maxWidth="100%">
					<Box
						key="Agregar Nuevo"
						style={{ position: 'absolute', top: '50px', zIndex: 100 }}
					>
						<Button variant="contained" color="success" onClick={() => handleModalOpen('sections')} >
							Agregar Nueva Categoría
						</Button>
						<Button style={{ marginLeft: '10px' }} variant="contained" color="info" onClick={() => handleModalOpen('projects')} >
							Agregar Nuevo Proyecto
						</Button>
					</Box>
				</Box>
				<Header title={data.title} subtitle={data.description} />
				<Paper elevation={3} className="panel">
					<Box m="20px">
						<Box
							display="grid"
							gridTemplateColumns="repeat(12, 1fr)"
							gridAutoRows="140px"
							gap="20px"
							style={{ cursor: 'pointer' }}
						>
							{rows.map((row) => (
								<Box
									p={3}
									mt="25px"
									mb="25px"
									key={row.id}
									gridColumn="span 6"
									gridRow="span 2"
									backgroundColor={colors.primary[400]}
									onClick={() => handleProjectClick(row.id)}
								>
									<Box
										display="flex"
										justifyContent="space-between"
										alignItems="center"
									>
										<Box>
											<Typography
												variant="h5"
												fontWeight="600"
												color={colors.grey[100]}
											>
												{row.nombre}
												< hr noshade='-1'></hr>
											</Typography>
											<Typography
												variant="h8"
												fontWeight="200"
												color={colors.grey[100]}
											>
												{row.descripcion}
												<br /> <br />
											</Typography>
											<Typography
												variant="h3"
												fontWeight="bold"
												color={colors.greenAccent[500]}
											>
											</Typography>
										</Box>
									</Box>
								</Box>
							))}
						</Box>
					</Box>
				</Paper>
				<Modal
					open={modalOpen}
					onClose={handleModalClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Paper
						elevation={3}
						sx={{
							width: '50%',
							padding: 3,
							backgroundColor: '#f5f5f5',
							borderRadius: 2
						}}
					>
						{modalSection === 'sections' ? (
							<>
								<CreateSection />
							</>
						) : (
							<>
								<CreateProject data={''}/>
							</>
						)}
					</Paper>
				</Modal>
			</Grid>
		</Grid >
	);
};

export default ProjectsList;
