import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Paper, Button, Modal, Table, TableHead, TableRow, TableCell, TableContainer, TableBody } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import '../../theme/project.css';

import { sendPostData, getDataArray } from '../../system/getData';

import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

import { CreateSection, AddArticles, CreateProject } from "./addElements";
import CategoryItem from '../../components/projects/categoryItem';
import ImageUploader from "../globals/imgUploader";

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

const ProjectDetails = ({ data }) => {
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const [projData, setProjData] = useState(null);
    const [projCat, setProjCategories] = useState(null);
    const [catID, setCatID] = useState(null);
    const [projComp, setProjCompuestos] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const { config, configData } = UseAppConfig();

    const [formValues, setFormValues] = useState({
        proyTitle: '',
        proyDesc: '',
    });

    const [categories, setCategories] = useState([]);

    const fetchData = async () => {
        try {
            const apiData = await getDataArray(`${config.API_BASE_URL}/${data.getData}`, { id: data.id });
            setProjData(apiData.projectDetails[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await getDataArray(`${config.API_BASE_URL}/obtiene/categorias`, { proyID: data.id });
            console.log('Categorías recibidas:', response);
            const categoriesTree = buildCategoryTree(response);
            setCategories(categoriesTree);
        } catch (error) {
            console.error('Error fetching categories:', error);
            enqueueSnackbar('Error al cargar las categorías', { variant: 'error' });
        }
    };

    const buildCategoryTree = (categories) => {
        const categoryMap = {};
        const rootCategories = [];

        categories.forEach(category => {
            categoryMap[category.id] = { ...category, children: [] };
        });

        categories.forEach(category => {
            if (category.categoria_padre_id === null) {
                rootCategories.push(categoryMap[category.id]);
            } else if (categoryMap[category.categoria_padre_id]) {
                categoryMap[category.categoria_padre_id].children.push(categoryMap[category.id]);
            }
        });

        return rootCategories;
    };

    // Función para calcular el total acumulado
    const calculateTotalProjectCost = (categories) => {
        let total = 0;

        const calculateCategoryTotal = (category) => {
            let categoryTotal = category.precio || 0;

            if (category.children && category.children.length > 0) {
                category.children.forEach(subCategory => {
                    categoryTotal += calculateCategoryTotal(subCategory);
                });
            }

            return categoryTotal;
        };

        categories.forEach(category => {
            total += calculateCategoryTotal(category);
        });

        return total;
    };

    useEffect(() => {
        fetchData();
        fetchCategories();
    }, [data.id, data.getData]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    const ProjectDescription = () => {
        const totalProjectCost = categories.reduce((acc, category) => acc + category.totalConSubcategorias, 0);

        return (
            <Box p={(4)}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={3} className='projectImage'>
                        <div>
                            <img src={projData.imagen} alt={projData.nombre} width="100%" height="200" />
                            <ImageUploader imageName="miImagen.jpg" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                            <Typography variant="h2" align="left" gutterBottom>
                                {projData.nombre}
                            </Typography>
                            <Button
                                variant="contained"
                                color='warning'
                                style={{ minWidth: '25px' }}
                                onClick={() => handleModalOpen(projData.id, 'edit')}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </Box>
                        <hr noshade="-1"></hr>
                        <Typography variant="h4" gutterBottom>
                            Descripción:
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {projData.descripcion}
                            <hr nosshade="-1"></hr>
                        </Typography>
                        <Typography variant="h4" gutterBottom className='totalPrice'>
                            Costo Total Acumulado: <h3>{formatCurrency(totalProjectCost)}</h3>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const onAddSubcategory = (categoriaPadreId, subcategoryName) => {
        const newSubcategory = {
            nombre: subcategoryName,
            categoria_padre_id: categoriaPadreId,
            precio: 0,
            children: [],
            data: []
        };

        const addSubcategory = (categories) => {
            return categories.map(category => {
                if (category.id === categoriaPadreId) {
                    return {
                        ...category,
                        children: [...category.children, newSubcategory]
                    };
                }

                if (category.children.length > 0) {
                    return {
                        ...category,
                        children: addSubcategory(category.children)
                    };
                }

                return category;
            });
        };

        setCategories(addSubcategory(categories));

        const newSubcategoryData = {
            proyID: data.id,
            secTitle: subcategoryName,
            secDesc: '',
            categoria_padre_id: categoriaPadreId
        };

        handleAddCategory(newSubcategoryData);
    };

    const handleAddCategory = async (values) => {
        try {
            const result = await sendPostData(`${config.API_BASE_URL}/crea/categoria`, values);
            enqueueSnackbar(`Subcategoría creada: ${values.secTitle}`, { variant: 'success' });
            await fetchCategories();
        } catch (error) {
            console.error('Error al crear subcategoría:', error);
            enqueueSnackbar('Error al crear subcategoría', { variant: 'error' });
        }
    };

    const ProjectCategories = () => {
        return (
            <Box mt={4}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleModalOpen(projData.id, 'categorie')}
                    >
                        Crear Nueva Categoría
                    </Button>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" maxWidth="100%">
                    <Typography variant="h4" gutterBottom>
                        Categorías incluidas en el Proyecto:
                    </Typography>
                </Box>
                <Box mt={4}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#052c65', color: 'white', width: '78%' }}>Nombre de Categoría</TableCell>
                                    <TableCell align="right" sx={{ backgroundColor: '#052c65', color: 'white', width: '10%' }}>Precio</TableCell>
                                    <TableCell align="right" sx={{ backgroundColor: '#052c65', color: 'white', width: '20%' }}>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {categories.map(category => (
                                    <CategoryItem key={category.id} project={data.id} category={category} level={0} onAddSubcategory={onAddSubcategory} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        );
    };

    const handleModalOpen = (id, section) => {
        setModalSection(section);
        setCatID(id);
        setFormValues('');
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'categorie':
                return <CreateSection data={data.id} />;
            case 'projects':
                return <AddArticles proyID={data.id} catID={catID} />;
            case 'edit':
                return <CreateProject data={projData} />;
            default:
                return null;
        }
    };

    return (
        <Grid p={4} container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Header title={data.title} subtitle={data.description} />
                <Paper elevation={3} className="panel">
                    <Box p="20px">
                        <main>
                            <ProjectDescription />
                            <Box mt="20px">
                                <ProjectCategories />
                            </Box>
                        </main>
                    </Box>
                </Paper>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            minWidth: 700,
                            padding: 3,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2
                        }}
                    >
                        {renderModalContent()}
                    </Paper>
                </Modal>
            </Grid>
        </Grid>
    );
};

export default ProjectDetails;