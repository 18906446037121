import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Checkbox, ListItemText } from '@mui/material';

import { getDataArray } from '../../system/getData';
import { UseAppConfig } from '../../system/config';

const ComboBoxFilled = ({ data, index, value, onChange }) => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);

	const { config, configData } = UseAppConfig();

	useEffect(() => {
		const fetchDataTable = async () => {
			try {
				const response = await getDataArray(`${config.API_BASE_URL}/catalogo/${data.catalog}`);
				const jsonData = Array.isArray(response) ? response[0] : [];

				console.log('Datos obtenidos de la API:', jsonData);

				const formatData = jsonData.map(row => ({
					...row,
					status: row.status === 1 ? 'Activo' : 'Desactivado'
				}));

				setRows(formatData);
				console.log('Datos formateados:', formatData);
			} catch (error) {
				console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
				setRows([]);
			} finally {
				setLoading(false);
			}
		};
		fetchDataTable();
	}, [data.catalog]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const handleChange = (event) => {
		const { value } = event.target;
		onChange(
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	return (
		data.multiple == 1 ? (
			<div>
				<b style={{ marginTop: '20px', display: 'block' }}>{data.label}</b>
				<Select
					key={data.name}
					fullWidth
					variant="filled"
					label={data.label}
					onChange={handleChange}
					value={value}
					name={data.name}
					displayEmpty
					multiple
					sx={{ maxWidth: '170px' }}
					renderValue={(selected) => {
						if (selected.length === 0) {
							return <em>Seleccionar</em>;
						}
						return selected.map(id => rows.find(row => row.id === id)?.nombre).join(', ');
					}}
				>
					<MenuItem disabled value="">
						<em>Seleccionar</em>
					</MenuItem>

					{rows.map((row) => (
						<MenuItem key={row.id} value={row.id}>
							<Checkbox checked={value.indexOf(row.id) > -1} />
							<ListItemText primary={row.nombre} />
						</MenuItem>
					))}
				</Select>
			</div>
		) : (
			<div>
				<b style={{ marginTop: '20px', display: 'block' }}>{data.label}</b>
				<Select
					key={index}
					fullWidth
					variant="filled"
					type="text"
					label={data.label}
					onChange={onChange}
					value={value}
					name={data.name}
					displayEmpty
				>
					{data.name === "tipoArtID" ? (
						<MenuItem value=''>
							<em>Todos</em>
						</MenuItem>
					) : (
						<MenuItem value=''>
							<em>Seleccionar</em>
						</MenuItem>
					)}

					{rows.map((row) => (
						<MenuItem key={row.id} value={row.id}>
							{row.nombre}
						</MenuItem>
					))}
				</Select>
			</div>
		)
	);
};

export default ComboBoxFilled;
