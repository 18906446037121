export const searchParams = [
	{
		name: "clave",
		label: "Clave",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "descCorta",
		label: "Descripción",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "tipoArtID",
		label: "Tipo",
		gridColumn: "span 1",
		formType: "text",
		catalog: "tipo"
	},
	{
		name: "famID",
		label: "Familia",
		gridColumn: "span 1",
		formType: "text",
		catalog: "familia"
	},
	{
		name: "unidadID",
		label: "Unidad",
		gridColumn: "span 1",
		formType: "text",
		catalog: "unidad"
	},
];