export const newCompound = [
	{
	  name: "clave",
	  label: "Clave",
	  gridColumn: "span 1",
	  formType: "text"
	},
	{
		name: "tipoArtID",
		label: "Tipo",
		gridColumn: "span 1",
		formType: "text",
		catalog: "tipo"
	},
	{
	  name: "famID",
	  label: "Familia",
	  gridColumn: "span 1",
	  formType: "text",
	  catalog: "familia"
	},
	{
	  name: "cuentaID",
	  label: "Cuenta",
	  gridColumn: "span 1",
	  formType: "text",
	  catalog: "cuenta"
	},
	{
	  name: "unidadID",
	  label: "Unidad",
	  gridColumn: "span 1",
	  formType: "text",
	  catalog: "unidad"
	},
	{
	  name: "descCorta",
	  label: "Descripción",
	  gridColumn: "span 1",
	  formType: "text"
	},
	{
	  name: "descLarga",
	  label: "Descripción Larga",
	  gridColumn: "span 2",
	  formType: "text"
	},
	{
	  name: "imagen",
	  label: "imagen",
	  gridColumn: "span 1",
	  formType: "text"
	}
];

export const editCompound = [
	{
	  name: "clave",
	  label: "Clave",
	  gridColumn: "span 1",
	  formType: "text"
	},
	{
	  name: "famID",
	  label: "Familia",
	  gridColumn: "span 1",
	  formType: "text",
	  catalog: "familia"
	},
	{
	  name: "cuentaID",
	  label: "Cuenta",
	  gridColumn: "span 1",
	  formType: "text",
	  catalog: "cuenta"
	},
	{
	  name: "unidadID",
	  label: "Unidad",
	  gridColumn: "span 1",
	  formType: "text",
	  catalog: "unidad"
	},
	{
	  name: "descCorta",
	  label: "Descripción",
	  gridColumn: "span 1",
	  formType: "text"
	},
	{
	  name: "descLarga",
	  label: "Descripción Larga",
	  gridColumn: "span 2",
	  formType: "text"
	},
	{
	  name: "imagen",
	  label: "imagen",
	  gridColumn: "span 1",
	  formType: "text"
	},
	{
	  name: "status",
	  label: "Status",
	  gridColumn: "span 1",
	  formType: "text"
	}
];

export const editAmount = [
	{
	  name: "cantidad",
	  label: "Cantidad",
	  gridColumn: "span 1",
	  formType: "text"
	}
];