export const newArticle = [
	{
		name: "clave",
		label: "Clave",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "tipoArtID",
		label: "Tipo",
		gridColumn: "span 1",
		formType: "text",
		catalog: "tipo"
	},
	{
		name: "famID",
		label: "Familia",
		gridColumn: "span 1",
		formType: "text",
		catalog: "familia"
	},
	{
		name: "cuentaID",
		label: "Cuenta",
		gridColumn: "span 1",
		formType: "text",
		catalog: "cuenta"
	},
	{
		name: "unidadID",
		label: "Unidad",
		gridColumn: "span 1",
		formType: "text",
		catalog: "unidad"
	},
	{
		name: "descCorta",
		label: "Descripción",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "descLarga",
		label: "Descripción Detallada",
		gridColumn: "span 2",
		formType: "text"
	},
	{
		name: "imagen",
		label: "Imagen",
		gridColumn: "span 1",
		formType: "text"
	}
];

export const editArticle = [
	{
		name: "clave",
		label: "Clave",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "famID",
		label: "Familia",
		gridColumn: "span 1",
		formType: "text",
		catalog: "familia"
	},
	{
		name: "cuentaID",
		label: "Cuenta",
		gridColumn: "span 1",
		formType: "text",
		catalog: "cuenta"
	},
	{
		name: "unidadID",
		label: "Unidad",
		gridColumn: "span 1",
		formType: "text",
		catalog: "unidad"
	},
	{
		name: "descCorta",
		label: "Descripción",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "descLarga",
		label: "Descripción Larga",
		gridColumn: "span 2",
		formType: "text"
	},
	{
		name: "imagen",
		label: "imagen",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "status",
		label: "Status",
		gridColumn: "span 1",
		formType: "text",
		catalog: "status"
	}
];

export const precioCompra = [
	{
		name: "precioCompra",
		label: "precioCompra",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "precioVenta",
		label: "precioCompra",
		gridColumn: "span 1",
		formType: "text"
	},
];