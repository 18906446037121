import React, { useEffect, useState } from 'react';
import { Box, useTheme, Typography, Button, TextField } from '@mui/material';

import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";

import { sendPostData, getPostData } from '../../system/getData';
import { UseAppConfig } from '../../system/config';

export const AddCatalog = ({ secValue, secName }) => {
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();

	const [open, setOpen] = useState(false);

	const { config, configData } = UseAppConfig();

	const [formValues, setFormValues] = useState({
		nombre: '',
		descripcion: '',
		simbolo: '',
		estado: '',
		nombreCatalogo: secValue,
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		await handleFormSubmit(formValues);
		handleModalClose();
	};

	const handleFormSubmit = async (values) => {
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/nuevo/catalogo`, values);
			enqueueSnackbar('Proyecto Guardado', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar crear el nuevo proyecto', { variant: 'error' });
		}
	};

	const handleModalClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Agregar nuevo registro al {secName}
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="project-title"
					label="Nombre del Registro"
					variant="outlined"
					name="nombre"
					value={formValues.nombre}
					onChange={handleChange}
					sx={{
						width: '100%',
						maxWidth: '400px'
					}}
				/>
				<TextField
					required
					id="project-description"
					label="Estado"
					variant="outlined"
					name="estado"
					value='Activado'
					onChange={handleChange}
					disabled
					sx={{
						width: '100%',
						maxWidth: '200px'
					}}
				/>
				{(() => {
					switch (secValue) {
						case 'costos':
							break;
						case 'cuenta':
							break;
						case 'familia':
						case 'usuarios':
							return (
								<TextField
									required
									id="project-description"
									label="Descripción"
									variant="outlined"
									name="descripcion"
									value={formValues.descripcion}
									onChange={handleChange}
									multiline
									rows={10}
								/>
							);
							break;
						case 'ubicacion':
							break;
						case 'unidad':
							break;
						case 'tipo':
							break;
						case 'facturas':
							break;
						case 'movimientos':
							break;
						case 'polizas':
							return (
								<TextField
									required
									id="project-title"
									label="Símbolo"
									variant="outlined"
									name="simbolo"
									value={formValues.simbolo}
									onChange={handleChange}
									sx={{
										width: '100%',
										maxWidth: '200px'
									}}
								/>
							);
							break;
						default:
							return null;
							break;
					}
				})()}
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSubmit}
				>
					Agregar Registro
				</Button>
			</Box>
		</div>
	);
};

export const EditCatalog = ({ secValue, regID }) => {
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState(false);
	const [rcdData, setRcdData] = useState('');
	const [loading, setLoading] = useState(true);

	const { config, configData } = UseAppConfig();

	const [formValues, setFormValues] = useState({
		rcdID: '',
		nombre: '',
		descripcion: '',
		ubicacion: '',
		simbolo: '',
		estado: '',
		nombreCatalogo: secValue,
	});

	const fetchData = async () => {
		try {
			const jsonData = await getPostData(`${config.API_BASE_URL}/detallesCatalogo/${secValue}`, { id: regID });

			if (jsonData && jsonData.length > 0) {
				const row = jsonData[0];

				const formattedData = {
					rcdID: row.id || '',
					nombre: row.nombre || '',
					descripcion: row.descripcion || '',
					simbolo: row.simbolo || '',
					ubicacion: row.ubicacion || '',
					estado: row.status === 1 ? 'Activo' : 'Desactivado',
					nombreCatalogo: secValue,
				};

				setFormValues(formattedData);
				console.log(formattedData);
			} else {
				console.warn('No se encontraron datos en la respuesta del API');
			}
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
		}
	};

	useEffect(() => {
		fetchData();
	}, [regID]);

	if (loading) {
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				textAlign: 'center',
			}}
		>
			Cargando...
		</div>
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		await handleFormSubmit(formValues);
		handleModalClose();
	};

	const handleFormSubmit = async (values) => {
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/editaCatalogo/${secValue}`, values);
			enqueueSnackbar('Datos Actualizados', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar editar los datos de este registro', { variant: 'error' });
		}
	};

	const handleModalClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Edición del registro {formValues.nombre} con el ID #{regID}
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				{(() => {
					switch (secValue) {
						case 'costos':
						case 'cuenta':
						case 'familia':
						case 'usuarios':
						case 'unidad':
						case 'tipo':
						case 'facturas':
						case 'movimientos':
						case 'polizas':
							return (
								<div>
									<TextField
										required
										id="project-title"
										label="Nombre del Registro"
										variant="outlined"
										name="nombre"
										value={formValues.nombre}
										onChange={handleChange}
										sx={{
											width: '100%',
											maxWidth: '400px'
										}}
									/>
									<TextField
										required
										id="project-description"
										label="Estado"
										variant="outlined"
										name="estado"
										value={formValues.estado}
										onChange={handleChange}
										disabled
										sx={{
											width: '100%',
											maxWidth: '200px'
										}}
									/>
								</div>
							);
						default:
							return null;
							break;
					}
				})()}
				{(() => {
					switch (secValue) {
						case 'costos':
							break;
						case 'cuenta':
							break;
						case 'familia':
						case 'usuarios':
							return (
								<TextField
									required
									id="project-description"
									label="Descripción"
									variant="outlined"
									name="descripcion"
									value={formValues.descripcion}
									onChange={handleChange}
									multiline
									rows={10}
									fullWidth
								/>
							);
							break;
						case 'ubicacion':
							return (
								<TextField
									required
									id="project-description"
									label="Ubicacion"
									variant="outlined"
									name="ubicacion"
									value={formValues.ubicacion}
									onChange={handleChange}
									rows={4}
									fullWidth
								/>
							);
							break;
						case 'unidad':
							break;
						case 'tipo':
							break;
						case 'facturas':
							break;
						case 'movimientos':
							break;
						case 'polizas':
							return (
								<TextField
									required
									id="project-title"
									label="Símbolo"
									variant="outlined"
									name="simbolo"
									value={formValues.simbolo}
									onChange={handleChange}
									fullWidth
								/>
							);
							break;
						default:
							return null;
							break;
					}
				})()}
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSubmit}
				>
					Actualizar Datos
				</Button>
			</Box>
		</div>
	);
};
