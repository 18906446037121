import { DataTable, ExpandableDataTable } from "../../components/globals/dataTable";
import { articlesHeaders } from "../../data/tableHeaders/articles";
import { subContentHeaders } from "../../data/tableHeaders/subContent/articles";
import { searchParams } from "../../data/search/articles";

const Inventory = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
	const articlesConfig = {
		title: "Articulos",
		module: "articles",
		tableHeader: articlesHeaders,
		subContentHeader: subContentHeaders,
		searchParams: searchParams,
		description: "Listado de Articulos registrado en el sistema",
		endpoint: "datos/articulosCompletos",
		getData: "elementos/compuesto"
	}
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<ExpandableDataTable data={articlesConfig} />
		)
	// ========================================================================================
}

export default Inventory;