import { GeneraFormularioSimple } from "../../components/globals/globalForms";
import { usersFields } from "../../data/forms/users";
import * as yup from "yup";

const addNewRcd = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
		const initValues = {
			nombre: '',
			apPat: '',
			apMat: '',
			correo: '',
			telefono1: '',
			telefono2: '',
			direccion: '',
			tipoUsuID: '',
			status: ''
		};

		const dataConfig = {
			dbID: "id",
			title: "Nuevo Usuario",
			key: usersFields,
			section: 'users',
			module: 'users',
			description: "Ingrese los datos necesarios para crear un nuevo Usuario",
			titleBtn: "Nuevo Usuario",
			colorBtn: "secondary",
			msgSuccess: "Usuario creado exitosamente",
			msgError: "Error al crear el usuario",
			sendData: "nuevo/usuario"
		}

		const validateSchemas = yup.object().shape({
			nombre: yup.string().required('Requerido'),
			apPat: yup.string().required('Requerido'),
			apMat: yup.string().required('Requerido'),
			correo: yup.string().email('Correo inválido').required('Requerido'),
			telefono1: yup.string(),
			telefono2: yup.string(),
			direccion: yup.string(),
			tipoUsuID: yup.number().required('Requerido'),
		});
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<GeneraFormularioSimple 
				data={dataConfig} 
				initValues={initValues} 
				validateSchemas={validateSchemas} 
			/>
		)
	// ========================================================================================
}

export default addNewRcd;
