
export const subContentHeaders = [
	{
		"field": "hijo",
		"headerName": "Nombre",
		"headerAlign": "center",
		"align": "center",
		"flex": 1,
		"modal": true,
		"collapse": true
	},
	{
		"field": "nombreTipo",
		"headerName": "Tipo",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "descripcion",
		"headerName": "Descripción",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "nombreFamilia",
		"headerName": "Familia",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "cantidad",
		"headerName": "Cantidad",
		"flex": 1,
		"headerAlign": "center",
		"align": "center"
	},
	{
		"field": "total",
		"headerName": "Precio de Compra",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"currency": 1
	},
	{
		"field": "precioVenta",
		"headerName": "Precio de Venta",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"currency": 1
	},
	{
		"field": "percentage",
		"headerName": "Porcentaje",
		"flex": 1,
		"headerAlign": "center",
		"align": "center"
	},
	{
		"field": "statusElemento",
		"headerName": "Status",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	}
];