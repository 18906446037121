import { useParams } from "react-router-dom";

import ProjectDetails from "../../components/projects/projectDetails";
import { tableHeaders } from "../../data/tableHeaders/projects";

const Catalogs = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
	const { id } = useParams();
	const projectConfig = {
		id: id,
		title: "Proyectos",
		module: "projects",
		tableHeader: tableHeaders,
		description: "Detalles del Proyecto",
		getData: "detalle/proyecto"
	}
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
	return (
		<ProjectDetails data={projectConfig} />
	)
	// ========================================================================================
}

export default Catalogs;