import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

import { Link } from "react-router-dom";
import { tokens } from "../../data/tokens";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import "react-pro-sidebar/dist/css/styles.css";

import dataMenu from '../../data/menu';

const Item = ({ title, to, icon, selected, setSelected }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<MenuItem
			active={selected === title}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => {
				setSelected(title); 
				localStorage.removeItem('searchFilters');
			}}
			icon={icon}
		>
			<Typography>{title}</Typography>
			<Link to={to} />
		</MenuItem>
	);
};

const Sidebar = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [selected, setSelected] = useState("Dashboard");

	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				height: '100vh',
				width: isCollapsed ? '80px' : '250px',
				"& .pro-sidebar-inner": {
					background: `${colors.primary[600]} !important`,
				},
				"& .pro-icon-wrapper": {
					backgroundColor: "transparent !important",
				},
				"& .pro-inner-item": {
					padding: "5px 35px 5px 20px !important",
				},
				"& .pro-menu-item": {
					color: "#f1f2f3 !important",
				},
				"& .pro-inner-item:hover": {
					color: "#00aaff !important",
				},
				"& .pro-menu-item.active": {
					color: "#00aaff !important",
				},
			}}
		>
			<ProSidebar collapsed={isCollapsed}>
				<Menu iconShape="square">
					<MenuItem
						onClick={() => setIsCollapsed(!isCollapsed)}
						icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
						style={{
							margin: "10px 0 20px 0",
							color: colors.grey[100],
						}}
					>
						{!isCollapsed && (
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								ml="15px"
							>
								<Typography variant="h3" color={colors.grey[900]}>
									Panel de Control
								</Typography>
								<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
									<MenuOutlinedIcon />
								</IconButton>
							</Box>
						)}
					</MenuItem>

					{!isCollapsed && (
						<Box mb="25px">
							<Box display="flex" justifyContent="center" alignItems="center">
								<img
									alt="profile-user"
									width="100px"
									height="100px"
									src={`../../assets/user.png`}
									style={{ cursor: "pointer", borderRadius: "50%" }}
								/>
							</Box>
							<Box textAlign="center">
								<Typography
									variant="h3"
									color={colors.grey[700]}
									fontWeight="bold"
									sx={{ m: "10px 0 0 0" }}
								>
									Enrique Alvarado
								</Typography>
								<Typography variant="h5" color={colors.blueAccent[500]}>
									Desarrollador de Software
								</Typography>
							</Box>
						</Box>
					)}

					<Box paddingLeft={isCollapsed ? undefined : "10%"}>
						<Typography
							variant="h6"
							color={colors.grey[900]}
							sx={{ m: "15px 0 5px 20px" }}
						>
							Menu Principal
						</Typography>
						{dataMenu.map((menuData, index) => (
							<Item
								key={menuData.id}
								title={menuData.title}
								to={menuData.dir}
								icon={<HomeOutlinedIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
						))}
					</Box>
				</Menu>
			</ProSidebar>
		</Box>
	);
};

export default Sidebar;
