
import React, { useEffect, useState } from 'react';
import { Box, useTheme, Container, Grid, Typography, Paper, Modal, Button } from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';
import { tokens } from "../../data/tokens";

import { useSnackbar } from 'notistack';
import { getPostData, getDataArray } from '../../system/getData';

import { AddCatalog, EditCatalog } from "./../catalogs/addCatalogs";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: 'lightslategrey',
		color: '#fff',
		fontSize: '14px',
		height: '10px',
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'bold',
	},
	'& .MuiDataGrid-cell': {
		padding: '8px',
		fontSize: '14px',
	},
}));

const CatalogList = ({ data }) => {
	const [dataTable, setDataTable] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalSectionName, setModalSectionName] = useState('');
	const [modalSectionValue, setModalSectionValue] = useState('');
	const [modalSection, setModalSection] = useState(false);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData, loading } = UseAppConfig();

	const fetchDataTable = async (field) => {
		try {
			const jsonData = await getPostData(`${config.API_BASE_URL}/catalogo/${field}`, {});

			let formattedData = jsonData.map(row => ({
				...row,
				status: row.status === 1 ? 'Activo' : 'Desactivado',
				module: row.tipo === 'Simple' ? 'articles' : 'compounds'
			}));

			setDataTable(prevState => ({ ...prevState, [field]: formattedData }));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setError(`No ha sido posible obtener los datos del Catálogo: ${field}`);
		}
	};

	useEffect(() => {
		const fetchAllData = async () => {
			try {
				setIsLoading(true);
				const fetchPromises = data.tableHeader.map(catalog => fetchDataTable(catalog.field));
				await Promise.all(fetchPromises);
			} catch (error) {
				setError('Se ha generado un error a la hora de intentar obtener los datos.');
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchAllData();
	}, [data.tableHeader]);

	if (isLoading) {
		return <div>Cargando...</div>;
	}

	if (error) {
		return <div>{error}</div>;
	}

	const handleModalOpen = (catalogValue, catalogName, section) => {
		setModalSectionValue(catalogValue);
		setModalSectionName(catalogName);
		setModalSection(section);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '50%',
		maxHeight: '80vh',
		overflowY: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4
	};

	return (
		<Grid p={(4)} container justifyContent="center" className="row-printable">
			<Grid item xs={12} md={12}>
				<Header title={data.title} subtitle={data.description} />
				<Paper elevation={3} className="panel">
					<Box m="20px">
						<Box
							display="grid"
							gridTemplateColumns="repeat(12, 1fr)"
							gridAutoRows="140px"
							gap="20px"
						>
							{data.tableHeader.map((catalog, index) => (
								<Box
									key={index}
									gridColumn="span 6"
									gridRow="span 2"
									backgroundColor={colors.primary[400]}
								>
									<Box
										mt="25px"
										p="0 30px"
										display="flex"
										justifyContent="space-between"
										alignItems="center"
									>
										<Box>
											<Typography
												style={{
													width: '300px',
												}}
												variant="h5"
												fontWeight="600"
												color={colors.grey[100]}
											>
												{catalog.name}
												<br /> <br /> <br />
											</Typography>
										</Box>
										<Box display="flex" justifyContent="flex-end" width="100%">
											<Button
												style={{
													width: '10px',
													minWidth: '10px',
													top: '-20px',
													right: '-20px',
													zIndex: 100
												}}
												variant="contained"
												color="success"
												onClick={() => handleModalOpen(catalog.field, catalog.name, 'new')}
											>
												<FontAwesomeIcon icon={faPlus} />
											</Button>
										</Box>
									</Box>
									<Box height="250px" m="-20px 0 0 0">
										<StyledDataGrid
											rows={dataTable[catalog.field] || []}
											columns={[
												...(catalog.columns || []),
												{
													field: 'edit',
													headerName: 'Editar',
													width: 100,
													headerAlign: 'center',
													align: 'center',
													renderCell: (params) => (
														<Box
															display="flex"
															justifyContent="center"
															width="100%"
														>
															<Button
																style={{
																	width: '10px',
																	minWidth: '10px',
																}}
																variant="contained"
																color="warning"
																onClick={() => handleModalOpen(catalog.field, params.row.id, 'edit')}
															>
																<FontAwesomeIcon icon={faEdit} />
															</Button>
														</Box>
													),
												},
											]}
											className="datos-tabla"
											rowHeight={35}
										/>
									</Box>
								</Box>
							))}
							<Modal
								open={modalOpen}
								onClose={handleModalClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Paper
									elevation={3}
									sx={{
										width: '100%',
										maxWidth: '700px',
										margin: '0 auto',
										padding: 2,
									}}
								>
									{modalSection === 'new' ? (
										<AddCatalog secValue={modalSectionValue} secName={modalSectionName} />
									) : (
										<EditCatalog secValue={modalSectionValue} regID={modalSectionName} />
									)}
								</Paper>
							</Modal>

						</Box>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default CatalogList;
