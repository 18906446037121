
export const tableHeaders = [
	{
		"field": "nombre",
		"headerName": "nombre",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "apPat",
		"headerName": "apPat",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "apMat",
		"headerName": "apMat",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "correo",
		"headerName": "correo",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "telefono1",
		"headerName": "telefono1",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "status",
		"headerName": "status",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"botons": {
			"editar": {
				"label": "Editar",
				"class": "warning",
				"type": "link",
				"action": "edit/${row.id}"
			},
			"eliminar": {
				"label": "Eliminar",
				"class": "error",
				"type": "link",
				"action": "edit/${row.id}"
			}
		}
	}
];
