import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Collapse, Box, IconButton, Typography, TableHead, Paper } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { UseAppConfig } from '../../system/config';
import { getDataArray } from '../../system/getData';

const ArticleItem = ({ articulo, nivel, onExpand }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
        onExpand(articulo.id, isExpanded);
    };

    return (
        <>
            <TableRow>
                <TableCell align="center" style={{ paddingLeft: `${nivel * 20}px` }}>{articulo.clave}</TableCell>
                <TableCell align="center">{articulo.tipo}</TableCell>
                <TableCell align="center">{articulo.descCorta}</TableCell>
                <TableCell align="center">{articulo.familia}</TableCell>
                <TableCell align="center">{articulo.unidad}</TableCell>
                <TableCell align="center">{formatCurrency(articulo.precioVenta)}</TableCell>
            </TableRow>
            {articulo.children?.length > 0 && (
                <TableRow>
                    <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table>
                                    <TableBody>
                                        {articulo.children.map((childArticulo) => (
                                            <ArticleItem key={childArticulo.id} articulo={childArticulo} nivel={nivel + 1} onExpand={onExpand} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export const ArticlesTable = ({ data, nivel = 0 }) => {
    const { config } = UseAppConfig();
    const [articulos, setArticulos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const apiData = await getDataArray(`${config.API_BASE_URL}/${data.endpoint}`, { params: data.endpointParams });
                console.log('Datos obtenidos desde la API:', apiData);
                if (apiData && apiData.length > 0) {
                    setArticulos(apiData);
                } else {
                    console.warn('No se encontraron artículos o la estructura no es correcta.');
                }
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [data.endpoint, data.endpointParams, config.API_BASE_URL]);

    const handleExpand = (id, wasExpanded) => {
        console.log(`Acción de expansión para el artículo con ID: ${id}, estado previo: ${wasExpanded ? 'expandido' : 'colapsado'}`);
    };

    if (loading) {
        return <div style={{ textAlign: 'center' }}>Cargando artículos...</div>;
    }

    if (articulos.length === 0) {
        return (
            <div
                style={{
                    textAlign: 'center',
                    backgroundColor: '#cccccc',
                    color: '#721c24',
                    padding: '20px',
                    marginTop: '20px',
                    marginLeft: '20px',
                    marginRight: '15px',
                    height: '5vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px'
                }}
            >
                No se ha agregado ningún artículo.
            </div>
        );      
    }

    return (
        <TableContainer
            component={Paper}
            sx={{
                marginLeft: `${nivel * 20}px`, 
                marginTop: '20px', 
                marginLeft: '25px',
                maxWidth: 'calc(100% - 40px)' 
            }}
        >
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#1976d2' }}>
                        <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Clave</TableCell>
                        <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Tipo</TableCell>
                        <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Descripción</TableCell>
                        <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Familia</TableCell>
                        <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Unidad</TableCell>
                        <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Precio de Venta</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {articulos.map((articulo) => (
                        <ArticleItem key={articulo.id} articulo={articulo} nivel={nivel} onExpand={handleExpand} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
