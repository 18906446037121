import React, { useEffect, useState } from 'react';
import { Box, Paper, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Button, useTheme } from '@mui/material';

import { UseAppConfig } from '../../system/config';
import { tokens } from "../../data/tokens";
import { getDataArray } from '../../system/getData';

const ModalContent = ({ data, modalContent }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [currentCantidad, setCurrentCantidad] = useState(formatCurrency(modalContent.precioCompra));

	const { config, configData } = UseAppConfig();

	const fetchDataTable = async () => {
		try {
			setIsLoading(true);
			const jsonData = await getDataArray(`${config.API_BASE_URL}/elementos/compuesto`, { id: modalContent.id });
			const totalGeneral = jsonData.reduce((sum, row) => sum + (row.precio * row.cantidad), 0);
			
			const formatData = jsonData.map(row => {
				const total = row.precio * row.cantidad;
				const percentage = totalGeneral > 0 ? (total / totalGeneral) * 100 : 0;
				return {
					...row,
					status: row.status == 1 ? 'Activo' : 'Desactivado',
					cantidadAnterior: row.cantidad,
					total: total,
					percentage: percentage
				};
			});

			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (modalContent && modalContent.precioCompra) {
			setCurrentCantidad(formatCurrency(modalContent.precioCompra));
		}
		fetchDataTable();
	}, [modalContent, modalContent.id, data.sendData]);

	if (isLoading) {
		return <div>Cargando...</div>;
	}

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 900,
		maxHeight: '80vh',
		overflowY: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4
	};

	return (
		<Box sx={{ ...modalStyle }}>
			<Typography id="modal-modal-title" variant="h6" component="h2">
				<h4>Detalles del Artículo Compuesto: {modalContent.clave}</h4>
			</Typography>
			<Typography id="modal-modal-description" sx={{ mt: 2 }}>
				<Paper elevation={3} className="panel">
					<ul className="list-unstyled mb0">
						<li><strong>Nombre:</strong> {modalContent.descCorta}</li>
						<li><strong>Clave</strong> {modalContent.clave}</li>
						<li><strong>Tipo:</strong> {modalContent.tipo}</li>
						<li><strong>Familia:</strong> {modalContent.familia}</li>
						<li><strong>Cuenta:</strong> {modalContent.cuenta}</li>
						<li><strong>Tipo de Unidad:</strong> por {modalContent.unidad}</li>
						<li style={{ marginBottom: '35px' }}><strong>Descripción del Servicio:</strong><br />
							{modalContent.descLarga}</li>
					</ul>
				</Paper>
				<Box className="invoice-footer" mt={3} textAlign="center">
					<Container>
						<Grid container justifyContent="center" className="row-printable">
							<Grid item xs={12} md={12}>
								<Paper elevation={3} className="panel">
									<Box className="invoice-items">
										<div className="price-card">
											<div className="price-card-content">
												<h2>Precio de Compra:</h2>
												<p className="price">{currentCantidad}</p>
											</div>
										</div>
										<br />
										<strong>Este Compuesto está conformado por los siguientes Artículos:</strong>
										<br /><br />
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow style={{ backgroundColor: colors.grey[900] }}>
														<TableCell className="per5 text-center"><b>Hijo</b></TableCell>
														<TableCell className="per5 text-center"><b>Tipo</b></TableCell>
														<TableCell className="per25 text-center"><b>Precio</b></TableCell>
														<TableCell className="per5 text-center"><b>Total</b></TableCell>
														<TableCell className="per25 text-center"><b>Porcentaje</b></TableCell>
														<TableCell className="per25 text-center"><b>Familia</b></TableCell>
														<TableCell className="per5 text-center"><b>Cantidad</b></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{rows.map((row) => (
														<TableRow key={row.id}>
															<TableCell className="text-center">{row.hijo}</TableCell>
															<TableCell className="text-center">{row.nombreTipo}</TableCell>
															<TableCell className="text-center">{formatCurrency(row.precio)}</TableCell>
															<TableCell className="text-center">{formatCurrency(row.total)}</TableCell>
															<TableCell className="text-center">{row.percentage.toFixed(2)}%</TableCell>
															<TableCell className="text-center">{row.nombreFamilia}</TableCell>
															<TableCell className="text-center">{row.cantidad}</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Box>
								</Paper>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Typography>
		</Box>
	);
};

export default ModalContent;
