export const usersFields = [
	{
		name: "nombre",
		label: "Nombre",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "apPat",
		label: "Apellido Paterno",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "apMat",
		label: "Apellido Materno",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "correo",
		label: "Correo Electrónico",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "telefono1",
		label: "Teléfono",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "telefono2",
		label: "Celular",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "tipoUsuID",
		label: "Tipo de Usuario",
		gridColumn: "span 2",
		formType: "text",
		catalog: "usuarios"
	},
	{
		name: "status",
		label: "Status",
		gridColumn: "span 1",
		formType: "text",
		catalog: "status"
	}
];
