import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, TextField, Container, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme } from '@mui/material';

import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';
import { sendPostData } from '../../system/getData';
import useMediaQuery from "@mui/material/useMediaQuery";

import GetCompoundAmounts from "../compounds/compoundList";
import ComboBoxFilled from "../globals/comboBoxFilled";

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

export const GeneraFormularioSimple = ({ data, initValues, validateSchemas }) => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const isNonMobile = useMediaQuery("(min-width:600px)");

	const { config, configData } = UseAppConfig();

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const handleFormSubmit = async (values) => {
		console.log('Detalles del Registro: ' + JSON.stringify(values));
		console.log('Enviando datos a la API');
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/${data.sendData}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar(data.msgSuccess, { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			console.error('Error al enviar datos:', error);
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<Box
			sx={{
				width: "90%",
				backgroundColor: "#f0f0f0",
				margin: "0 auto",
				padding: "20px",
				borderRadius: "10px",
			}}
		>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
				<Button variant="contained" onClick={handleBack} color="success" autoFocus>
					Regresar
				</Button>
			</Box>

			<Header title={data.title} subtitle={data.description} />
			<Formik
				onSubmit={handleFormSubmit}
				initialValues={initValues}
			// validationSchema={validateSchemas}
			>
				{({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					setFieldValue,
				}) => (
					<form onSubmit={(e) => {
						console.log('Formulario intentando enviarse');
						handleSubmit(e);
					}}>
						<Box
							sx={{
								width: "85%",
								margin: "0 auto",
								borderRadius: "15px",
								display: "grid",
								gap: "10px",
								gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
							}}
						>
							{data.key.map((field, index) => (
								field.catalog?.length > 0 ? (
									<ComboBoxFilled
										data={field}
										index={index}
										value={values[field.name]}
										onChange={(event) => setFieldValue(field.name, event.target.value)}
									/>
								) : (
									<div key={index}>
										<b style={{ marginTop: '20px', display: 'block' }}>{field.label}</b>
										<TextField
											key={index}
											fullWidth
											variant="filled"
											type="text"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values[field.name]}
											name={field.name}
											disabled={field.disabled}
											error={!!touched[field.name] && !!errors[field.name]}
											helperText={touched[field.name] && errors[field.name]}
											sx={{ gridColumn: field.gridColumn }}
										/>
									</div>
								)
							))}
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color={data.colorBtn} variant="contained">
								{data.titleBtn}
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export const FormatoArticulosCompuestos = ({ data, initValues, validateSchemas }) => {
	const [setRows] = useState([]);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const currentDate = new Date();

	const { config, configData } = UseAppConfig();

	const [values, setValues] = useState({});
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const formattedDate = currentDate.toLocaleDateString('es-ES', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	});

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const handleFormSubmit = async (values) => {
		console.log('Nuevo Precio de Compra: ' + JSON.stringify(values));
		try {
			const result = await sendPostData(`${config.API_BASE_URL}${data.sendData}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar(data.msgSuccess, { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const handleSaleSubmit = async (values) => {
		console.log('Nuevo Precio de Venta: ' + JSON.stringify(values));
		try {
			const result = await sendPostData(`${config.API_BASE_URL}${data.sendData2}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar('Precio de Venta actualizado exitosamente', { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setValues({
			...values,
			[name]: value,
		});

		if (value !== '') {
			setIsButtonDisabled(false);
		} else {
			setIsButtonDisabled(true);
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<Container maxWidth="x2">
			<Header title={data.title} subtitle={data.description} />
			<Grid container justifyContent="center" className="row-printable">
				<Grid item xs={12} md={12}>
					<Paper elevation={3} className="panel">
						<Box p={3} className="panel-body">
							<Grid item xs={12} sm={6}>
								<Box className="invoice-from" textAlign="right">
									{(() => {
										switch (initValues.tipo) {
											case 'Simple':
												return (
													<Formik
														initialValues={initValues}
														validationSchema={validateSchemas}
														onSubmit={handleFormSubmit}
													>
														{({
															values,
															errors,
															touched,
															handleBlur,
															handleChange,
															handleSubmit,
															isValid,
															dirty
														}) => (
															<div style={{ position: 'absolute', top: '130px', right: '50px' }}>
																<Box display="flex" justifyContent="space-between">
																	{/* Primer Formulario para Precio de Compra */}
																	<Box>
																		<form onSubmit={handleSubmit}>
																			<Box>
																				<div style={{ marginBottom: '-24px' }}>
																					<span>Precio de Compra</span><br />
																					<span style={{ position: 'absolute', marginTop: '25px', fontSize: '40px', color: 'green', textAlign: 'center' }}>$</span>
																					<TextField
																						variant="filled"
																						type="number"
																						className="input-number"
																						onBlur={handleBlur}
																						onChange={handleChange}
																						value={values['precioCompra']}  // Se usa 'precioCompra'
																						name="precioCompra"
																						sx={{
																							width: '135px',
																							'& .MuiInputBase-input': {
																								fontSize: '40px',
																								color: 'green',
																								textAlign: 'center'
																							},
																						}}
																					/>
																				</div>
																			</Box>
																			<Box display="flex" justifyContent="end" mt="20px">
																				<Button
																					type="submit"
																					color={data.colorBtn}
																					variant="contained"
																					disabled={!dirty || !isValid}
																				>
																					{data.titleBtn}
																				</Button>
																			</Box>
																		</form>
																	</Box>

																	{/* Segundo Formulario para Precio de Venta */}
																	<Formik
																		initialValues={initValues}
																		validationSchema={validateSchemas}
																		onSubmit={handleSaleSubmit}
																	>
																		{({
																			values: saleValues,
																			handleChange: handleSaleChange,
																			handleSubmit: handleSaleFormSubmit,
																			isValid: isSaleValid,
																			dirty: isSaleDirty
																		}) => (
																			<Box style={{ marginLeft: '20px', marginTop: '0px' }}>
																				<form onSubmit={handleSaleFormSubmit}>
																					<Box>
																						<div style={{ marginBottom: '-24px' }}>
																							<span>Precio de Venta</span><br />
																							<span style={{ position: 'absolute', marginTop: '25px', fontSize: '40px', color: 'blue', textAlign: 'center' }}>$</span>
																							<TextField
																								variant="filled"
																								type="number"
																								className="input-number"
																								onChange={handleSaleChange}
																								value={saleValues['precioVenta']}  // Se usa 'precioVenta'
																								name="precioVenta"
																								sx={{
																									width: '120px',
																									'& .MuiInputBase-input': {
																										fontSize: '40px',
																										color: 'blue',
																										textAlign: 'center'
																									},
																								}}
																							/>
																						</div>
																					</Box>
																					<Box display="flex" justifyContent="end" mt="20px">
																						<Button
																							type="submit"
																							color={data.colorBtn2}
																							variant="contained"
																							disabled={!isSaleDirty || !isSaleValid}
																						>
																							{data.titleBtn2}
																						</Button>
																					</Box>
																				</form>
																			</Box>
																		)}
																	</Formik>
																</Box>
															</div>
														)}
													</Formik>
												);
											default:
												return null;
										}
									})()}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box className="invoice-details" mt={3}>
									<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
										<Button variant="contained" onClick={handleBack} color="success" autoFocus>
											Regresar
										</Button>
									</Box>
									<Box className="invoice-to" mt={3}>
										<Paper className="well">
											<ul className="list-unstyled mb0">
												<li><strong>Nombre:</strong> {initValues.descCorta}</li>
												<li><strong>Clave</strong> {initValues.clave}</li>
												<li><strong>Tipo:</strong> {initValues.tipo}</li>
												<li><strong>Familia:</strong> {initValues.familia}</li>
												<li><strong>Cuenta:</strong> {initValues.cuenta}</li>
												<li><strong>Tipo de Unidad:</strong> por {initValues.unidad}</li>
												<br /><br />
												<li style={{ marginBottom: '35px' }}><strong>Descripción del Servicio:</strong><br />
													{initValues.descLarga}</li>
											</ul>
										</Paper>
									</Box>
								</Box>
								{(() => {
									switch (initValues.tipo) {
										case 'Simple':
											return (
												<Box className="invoice-items">
													<TableContainer>
														<Table>
															<TableHead>
																<TableRow style={{ backgroundColor: colors.grey[900] }}>
																	<TableCell className="per70 text-left"><b>Descripción</b></TableCell>
																	<TableCell className="per5 text-center"><b>Estado</b></TableCell>
																	<TableCell className="per25 text-center"><b>Precio de Compra actual</b></TableCell>
																	<TableCell className="per5 text-center"><b>Acciones</b></TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																<TableRow>
																	<TableCell>{initValues.descLarga}</TableCell>
																	<TableCell className="text-center">{initValues.status}</TableCell>
																	<TableCell className="text-center">{formatCurrency(initValues.precioCompra)}</TableCell>
																	<TableCell className="text-center">
																		<Link key="Editar" to={`../${data.module}/edit/${initValues.id}`} style={{ marginRight: '5px' }}>
																			<Button type="submit" color="warning" variant="contained">
																				Editar
																			</Button>
																		</Link>
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</TableContainer>
												</Box>
											);
										case 'Compuesto':
											return (
												<Box className="invoice-footer" mt={3} textAlign="center">
													<GetCompoundAmounts
														data={data}
														initValues={initValues}
													/>
												</Box>
											);
										default:
											return null;
									}
								})()}
								<Box className="invoice-footer" mt={3} textAlign="center">
									<Typography>Generado el {formattedDate}</Typography>
								</Box>
							</Grid>
						</Box>
					</Paper >
				</Grid >
			</Grid >
		</Container >
	);
};