import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Button, useTheme } from '@mui/material';

import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';
import { sendPostData, getDataArray } from '../../system/getData';
import { UseAppConfig } from '../../system/config';

const AddArticles = ({ data, initValues }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);

	const [selectedItems, setSelectedItems] = useState([]);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [open, setOpen] = useState(false);

	const { config, configData } = UseAppConfig();

	const handleCheckboxChange = (id, padreID) => {
		setSelectedItems(prevSelectedItems => {
			const isSelected = prevSelectedItems.some(item => item.id === id);
			if (isSelected) {
				return prevSelectedItems.filter(item => item.id !== id);
			} else {
				return [...prevSelectedItems, { id, padreID }];
			}
		});
	};

	const handleSave = async () => {
		try {
			console.log('Antes de enviar datos');
			const url = `${config.API_BASE_URL}/agrega/articulosCompuesto`;
			const params = { items: selectedItems };
			const result = await sendPostData(url, params);
			enqueueSnackbar('Artículos agregados exitosamente al Compuesto', { variant: 'success' });
			setOpen(false);
			window.location.reload();
		} catch (error) {
			console.error('Error al intentar agregar los Artículos al Compuesto:', error);
			enqueueSnackbar('Error al intentar agregar los Artículos al Compuesto', { variant: 'error' });
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const fetchDataTable = async () => {
			try {
				const jsonData = await getDataArray(`${config.API_BASE_URL}/elementos/disponibles`, { id: initValues.id });
				const formatData = jsonData.map(row => ({
					...row,
					status: row.status == 1 ? 'Activo' : 'Desactivado'
				}));
				setRows(formatData);
				console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
			} catch (error) {
				console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
				setRows([]);
			} finally {
				setLoading(false);
			}
		};
		fetchDataTable();
	}, [initValues.id, data.sendData]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	return (
		<div>
			<Button
				variant="contained"
				color="primary"
				className="addArticles"
				onClick={handleOpen}
				style={{ marginTop: '-20px', marginRight:'-25px' }}
			>
				Agregar Artículos
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<DialogTitle style={{ backgroundColor: colors.primary[900], marginBottom: "30px" }}>
					<h5>Listado de Artículos disponibles para este Compuesto:</h5>
				</DialogTitle>
				<DialogContent>
					<strong>A Continuación se enlistan los Artículos que no han sido asignados a este Compuesto:</strong>
					<br />
					<small>Seleccione los elementos que desee agregar al Compuesto y luego dé click en Guardar Elementos</small>
					<br /><br />
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow style={{ backgroundColor: colors.grey[900] }}>
									<TableCell className="per5 text-center"><b>Clave</b></TableCell>
									<TableCell className="per5 text-center"><b>Tipo</b></TableCell>
									<TableCell className="per25 text-center"><b>Descripción</b></TableCell>
									<TableCell className="per5 text-center"><b>Familia</b></TableCell>
									<TableCell className="per5 text-center"><b>Cuenta</b></TableCell>
									<TableCell className="per5 text-center"><b>Unidad</b></TableCell>
									<TableCell className="per5 text-center"><b>Precio</b></TableCell>
									<TableCell className="per5 text-center"><b>Estado</b></TableCell>
									<TableCell className="per5 text-center"><b>Seleccionar</b></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) => (
									<TableRow key={row.id}>
										<TableCell className="text-center">{row.clave}</TableCell>
										<TableCell className="text-center">{row.tipo}</TableCell>
										<TableCell className="text-center">{row.descCorta}</TableCell>
										<TableCell className="text-center">{row.familia}</TableCell>
										<TableCell className="text-center">{row.cuenta}</TableCell>
										<TableCell className="text-center">{row.unidad}</TableCell>
										<TableCell className="text-center">{formatCurrency(row.precioCompra)}</TableCell>
										<TableCell className="text-center">{row.status}</TableCell>
										<TableCell className="text-center">
											<Checkbox
												checked={selectedItems.some(item => item.id === row.id)}
												onChange={() => handleCheckboxChange(row.id, initValues.id)}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="secondary" onClick={handleSave}>
						Agregar Elementos
					</Button>
					<Button variant="contained" color="error" onClick={handleClose}>
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AddArticles;
