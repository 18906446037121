import React, { useState } from 'react';
import { TableRow, TableCell, Collapse, Box, IconButton, Button, TextField, Typography, TableContainer, Table, TableBody, Paper, TableHead, Modal } from '@mui/material';
import { ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddArticles } from "./addElements";
import { faFileAlt, faFolderPlus } from '@fortawesome/free-solid-svg-icons';

import { ArticlesTable } from '../../components/projects/articleItem';
import { articlesHeaders } from "../../data/tableHeaders/articles";
import { subContentHeaders } from "../../data/tableHeaders/subContent/articles";

const CategoryItem = ({ project, category, level, onAddSubcategory, totalPrice }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);
    const [newSubcategoryName, setNewSubcategoryName] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handleAddSubcategoryClick = () => {
        setIsAddingSubcategory(true);
    };

    const handleSaveSubcategory = () => {
        if (newSubcategoryName.trim()) {
            onAddSubcategory(category.id, newSubcategoryName);
            setNewSubcategoryName('');
            setIsAddingSubcategory(false);
        }
    };

    const handleCancelAddSubcategory = () => {
        setIsAddingSubcategory(false);
        setNewSubcategoryName('');
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const articlesConfig = {
        title: ``,
        module: "projects",
        tableHeader: articlesHeaders,
        endpoint: "datos/articulosProyectos",
        endpointParams: `proyID: ${project}, catID: ${category.id}`
    };

    return (
        <>
            <TableRow sx={{ '& > *': { padding: '4px 8px' } }}>
                <TableCell
                    sx={{
                        borderBottom: 'none',
                        position: 'relative',
                        paddingLeft: level > 0 ? `${level * 20}px` : '16px',
                        width: '78%'
                    }}
                >
                    {level > 0 && (
                        <Box sx={{
                            position: 'absolute',
                            left: `${(level - 1) * 20}px`,
                            top: 0,
                            bottom: 0,
                            borderLeft: '2px solid #ccc',
                        }} />
                    )}
                    {level > 0 && (
                        <Box sx={{
                            position: 'absolute',
                            left: `${(level - 1) * 20}px`,
                            top: '50%',
                            width: '20px',
                            borderTop: '2px solid #ccc',
                        }} />
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: `${level > 0 ? 16 : 0}px` }}>
                        <IconButton onClick={handleToggle} sx={{ marginRight: 1 }}>
                            {isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <Typography>{category.nombre}</Typography>
                    </Box>
                </TableCell>

                <TableCell align="right" sx={{ width: '10%' }}>
                    {formatCurrency(category.totalConSubcategorias)}
                </TableCell>

                <TableCell align="right" sx={{ width: '20%' }}>
                    <Button
                        variant="contained"
                        color="info"
                        style={{ minWidth: '25px', marginRight: '5px' }}
                        onClick={handleAddSubcategoryClick}
                    >
                        <FontAwesomeIcon icon={faFolderPlus} />
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ minWidth: '25px' }}
                        onClick={() => handleModalOpen()}
                    >
                        <FontAwesomeIcon icon={faFileAlt} />
                    </Button>
                </TableCell>
            </TableRow>

            {isAddingSubcategory && (
                <TableRow sx={{ '& > *': { padding: '4px 8px' } }}>
                    <TableCell colSpan={3} sx={{ paddingLeft: `${(level + 1) * 20}px` }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                            <TextField
                                size="small"
                                label="Nombre de la Subcategoría"
                                variant="outlined"
                                value={newSubcategoryName}
                                onChange={(e) => setNewSubcategoryName(e.target.value)}
                                sx={{ marginRight: 1, flexGrow: 1 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleSaveSubcategory}
                                startIcon={<Save />}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                size="small"
                                onClick={handleCancelAddSubcategory}
                                startIcon={<Cancel />}
                                sx={{ marginLeft: 1 }}
                            >
                                Cancelar
                            </Button>
                        </Box>
                    </TableCell>
                </TableRow>
            )}

            <TableRow sx={{ '& > *': { padding: 0 } }}>
                <TableCell style={{ padding: 0 }} colSpan={3}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box sx={{ marginTop: '-20px', paddingLeft: `${level * 20}px`, width: '100%' }}>
                            <ArticlesTable data={articlesConfig} />
                        </Box>
                        <Table>
                            <TableBody>
                                {category.children.map(subcat => (
                                    <CategoryItem key={subcat.id} project={project} category={subcat} level={level + 1} onAddSubcategory={onAddSubcategory} />
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        minWidth: 700,
                        padding: 3,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                    }}
                >
                    <AddArticles proyID={project} catID={category.id} />
                </Paper>
            </Modal>
        </>
    );
};

export default CategoryItem;
