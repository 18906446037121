import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Button, useTheme, TextField } from '@mui/material';

import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";

import { sendPostData, getDataArray } from '../../system/getData';
import { UseAppConfig } from '../../system/config';

export const AddArticles = ({ proyID, catID }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);

	const [selectedItems, setSelectedItems] = useState([]);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [open, setOpen] = useState(false);

	const { config, configData } = UseAppConfig();

	const fetchDataTable = async () => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/artproy/disponibles`, { proyID, catID });
			const formatData = jsonData.map(row => ({
				...row,
				status: row.status == 1 ? 'Activo' : 'Desactivado'
			}));
			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
	}, [proyID]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const handleCheckboxChange = (id) => {
		setSelectedItems(prevSelectedItems => {
			const isSelected = prevSelectedItems.some(item => item.id === id);
			if (isSelected) {
				return prevSelectedItems.filter(item => item.id !== id);
			} else {

				return [...prevSelectedItems, { proyID, catID, id }];
			}
		});
	};

	const handleSave = async () => {
		try {
			console.log('Antes de enviar datos');
			const url = `${config.API_BASE_URL}/agrega/articulosCatProy`;
			const params = { items: selectedItems };
			const result = await sendPostData(url, params);
			enqueueSnackbar('Artículos agregados exitosamente al Compuesto', { variant: 'success' });
			setOpen(false);
			window.location.reload();
		} catch (error) {
			console.error('Error al intentar agregar los Artículos al Compuesto:', error);
			enqueueSnackbar('Error al intentar agregar los Artículos al Compuesto', { variant: 'error' });
		}
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				<h2>Agregar Artículos dentro de la sección: {proyID} {catID}</h2>
			</Typography>
			<strong>A Continuación se enlistan las Categorías que pueden ser incluidas en este Proyecto:</strong>
			<br />
			<small>Seleccione los elementos que desee agregar al Proyecto y luego dé click en Guardar Elementos</small>
			<br /><br />
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow style={{ backgroundColor: colors.grey[900] }}>
							<TableCell className="per5 text-center"><b>Clave</b></TableCell>
							<TableCell className="per5 text-center"><b>Descripción</b></TableCell>
							<TableCell className="per5 text-center"><b>Familia</b></TableCell>
							<TableCell className="per5 text-center"><b>Tipo</b></TableCell>
							<TableCell className="per5 text-center"><b>Cuenta</b></TableCell>
							<TableCell className="per5 text-center"><b>Unidad</b></TableCell>
							<TableCell className="per5 text-center"><b>Activo</b></TableCell>
							<TableCell className="per5 text-center"><b>Agregar</b></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.id}>
								<TableCell className="text-center">{row.clave}</TableCell>
								<TableCell className="text-center">{row.descCorta}</TableCell>
								<TableCell className="text-center">{row.familia}</TableCell>
								<TableCell className="text-center">{row.tipo}</TableCell>
								<TableCell className="text-center">{row.cuenta}</TableCell>
								<TableCell className="text-center">{row.unidad}</TableCell>
								<TableCell className="text-center">{row.status}</TableCell>
								<TableCell className="text-center">
									<Checkbox
										checked={selectedItems.some(item => item.id === row.id)}
										onChange={() => handleCheckboxChange(row.id)}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
				<Button variant="contained" color="secondary" onClick={handleSave}>
					Agregar Elementos
				</Button>
			</div>
		</div>
	);
};

export const CreateSection = ({ data }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();
	
	const [sectionFormValues, setSectionFormValues] = useState({
        proyID: data,
		secTitle: '',
		secDesc: ''
	});

	const handleSectionChange = (e) => {
		const { name, value } = e.target;
		setSectionFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSectionSubmit = async (e) => {
		e.preventDefault();
		await handleFormSection(sectionFormValues);
	};

	const handleFormSection = async (values) => {
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/crea/categoria`, values);
			enqueueSnackbar('Sección Guardada Exitosamente', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar crear el nueva sección', { variant: 'error' });
		}
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Crear una nueva Categoría
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="project-title"
					label="Título de la Sección"
					variant="outlined"
					name="secTitle"
					value={sectionFormValues.secTitle}
					onChange={handleSectionChange}
					fullWidth
				/>
				<TextField
					required
					id="project-description"
					label="Descripción"
					variant="outlined"
					name="secDesc"
					value={sectionFormValues.secDesc}
					onChange={handleSectionChange}
					multiline
					rows={4}
					fullWidth
				/>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSectionSubmit}
				>
					Crear Sección
				</Button>
			</Box>
		</div>
	);
};

export const CreateProject = ({ data }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	// Estado inicial basado en `data`
	const [projectFormValues, setProjectFormValues] = useState(() => {
		if (data !== "") {
			return {
				proyId: data.id,
				proyTitle: data.nombre,
				proyDesc: data.descripcion,
				proyImagen: data.imagen,
			};
		} else {
			return {
				proyTitle: '',
				proyDesc: '',
			};
		}
	});

	const handleProjectChange = (e) => {
		const { name, value } = e.target;
		setProjectFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleProjectSubmit = async (e) => {
		e.preventDefault();
		await handleFormProject(projectFormValues);
	};

	const handleFormProject = async (values) => {
		try {
			if (data !== "") {
				const result = await sendPostData(`${config.API_BASE_URL}/edita/proyecto`, values);
				enqueueSnackbar('Proyecto Guardado Exitosamente', { variant: 'success' });
			} else {
				const result = await sendPostData(`${config.API_BASE_URL}/nuevo/proyecto`, values);
				enqueueSnackbar('Proyecto Guardado Exitosamente', { variant: 'success' });
			}
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar crear el nuevo proyecto', { variant: 'error' });
		}
	};
	return (
		<div>
			{data != '' ? (
				<Typography variant="h5" gutterBottom>
					Editar los datos de este Proyecto
				</Typography>
			) : (
				<Typography variant="h5" gutterBottom>
					Creación de Nuevo Proyecto
				</Typography>
			)}
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="project-title"
					label="Título del Proyecto"
					variant="outlined"
					name="proyTitle"
					value={projectFormValues.proyTitle}
					onChange={handleProjectChange}
					fullWidth
				/>
				<TextField
					required
					id="project-description"
					label="Descripción"
					variant="outlined"
					name="proyDesc"
					value={projectFormValues.proyDesc}
					onChange={handleProjectChange}
					multiline
					rows={10}
					fullWidth
				/>
				{data != '' ? (
					<Button
						variant="contained"
						sx={{
							marginTop: 2,
							backgroundColor: 'warning',
							color: 'white',
							display: 'block',
							marginLeft: 'auto',
							marginRight: 'auto'
						}}
						onClick={handleProjectSubmit}
					>
						Editar Proyecto
					</Button>
				) : (
					<Button
						variant="contained"
						sx={{
							marginTop: 2,
							backgroundColor: 'info',
							color: 'white',
							display: 'block',
							marginLeft: 'auto',
							marginRight: 'auto'
						}}
						onClick={handleProjectSubmit}
					>
						Crear Proyecto
					</Button>
				)}
			</Box>
		</div>
	);
};