import React, { useState } from 'react';
import { Button, Box, Input } from '@mui/material';

import { UseAppConfig } from '../../system/config';

const ImageUploader = ({ imageName }) => {
	const { config, configData } = UseAppConfig();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setSelectedFile(file);
        } else {
            alert('Por favor, selecciona un archivo de imagen.');
            setSelectedFile(null);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Por favor, selecciona una imagen antes de subir.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('imageName', imageName);

        try {
            const response = await fetch(`${config.API_BASE_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                alert('Imagen cargada con éxito');
                setSelectedFile(null);
            } else {
                alert('Error al cargar la imagen');
            }
        } catch (error) {
            console.error('Error al cargar la imagen:', error);
            alert('Error al cargar la imagen - Error: ' + error);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" p={2} >
            <Input
                type="file"
                onChange={handleFileChange}
                inputProps={{ accept: 'image/*' }
                }
            />
            < Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                sx={{ mt: 2 }}
            >
                Subir Imagen
            </Button>
        </Box>
    );
};

export default ImageUploader;

